<template>
  <div>
    <div class="mb-5">
      <p class="p4 color-green-2 ">
        Você pode incluir vários arquivos ao seu treinamento, basta preencher as
        opções e clicar em incluir.
      </p>
      <p class="p4 color-green-2 ">
        As informações serão exibidas por ordem de inclusão e podem ser
        ajustadas na tabela abaixo.
      </p>
    </div>
    <b-card border-variant="light" class="custom-card">
      <b-form-row class="alter-form">
        <b-col>
          TIPO
        </b-col>
        <b-col>
          <b-form-select
            v-model.lazy.trim="form.type"
            :state="validateField('type')"
            :options="typeOptions"
            ref="type"
            v-on:change="onChangeStepType(form.type)"
          />
        </b-col>
      </b-form-row>
      <b-form-row class="alter-form">
        <b-col>
          NOME DO PASSO
        </b-col>
        <b-col>
          <b-form-input
            ref="title"
            v-model.lazy.trim="form.title"
            :state="validateField('title')"
            placeholder="NOME DO PASSO"
          />
        </b-col>
      </b-form-row>
      <b-form-row
        class="alter-form"
        v-if="form.type && form.type.text == 'LINK'"
      >
        <b-col>
          LINK
        </b-col>
        <b-col>
          <b-form-input
            ref="link"
            v-model.lazy.trim="form.link"
            :state="validateField('link')"
            placeholder="LINK"
          />
        </b-col>
      </b-form-row>
      <div class="mt-5" v-if="form.type">
        <b-form-row>
          <b-col>
            <UploadFileBucket
              v-if="form.type && form.type.text != 'LINK'"
              @updateFile="eventToUpdateFilePrincipal"
              @markToDeletion="markToDeletion"
              @fileRemoved="eventToFileRemoved"
              @startUploadFile="eventUploadClickPrincipal"
              @errorUploadingFile="eventErrorUploadingFilePrincipal"
              :fileUploaded="
                links.filter(Boolean).filter((l) => l.principal)[0]
              "
              :description="'Anexar arquivo principal'"
              :msgErro="'É necessário inserir um arquivo principal.'"
              :trainingName="training.name"
              :required="true"
              :uploadFileMicroService="form.type && form.type.text !== 'VIDEO'"
            />
            <UploadFileBucketMultiple
              @updateFile="eventToUpdateFile"
              @fileRemoved="eventToRemoveFileMultiple"
              @markToDeletion="markToDeletion"
              @startUploadFile="eventUploadClickMultiple"
              @errorUploadingFile="eventErrorUploadingFileMultiple"
              :filesUploaded="links.filter(Boolean).filter((l) => !l.principal)"
              :description="'Anexar arquivo complementar'"
              :msgErro="'É necessário inserir um arquivo complementar.'"
              :trainingName="training.name"
              :required="true"
              :uploadFileMicroService="true"
            />
          </b-col>
        </b-form-row>
      </div>
      <b-form-row>
        <b-col class="button-include d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            id="resetFormButton"
            :disabled="this.loading"
            @click="this.resetForm"
          >
            Cancelar
          </b-button>
          <b-button
            form="alter-form"
            type="button"
            class="ml-4"
            variant="primary"
            id="submitButtonText"
            :disabled="
              this.loading ||
                this.loadingUploadFileMultiple ||
                this.loadingUploadFilePrincipal
            "
            @click="this.onSaveHandler"
          >
            {{ form.id ? "Editar" : "Incluir" }}
          </b-button>
        </b-col>
      </b-form-row>
    </b-card>
    <div v-show="this.loading">
      <b-card class="custom-card mt-5">
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="4"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
    <b-card
      border-variant="light"
      class="custom-card mt-5"
      v-show="!this.loading"
    >
      <table class="table b-table table-custom spaced-text">
        <thead>
          <tr>
            <th scope="col" class="ordering-head">Ordenação</th>
            <th scope="col" class="column-trail-header">Passo</th>
            <th scope="col" class="text-center">Arquivos</th>
            <th scope="col" class="text-center column-actions-header">Ações</th>
          </tr>
        </thead>
        <draggable v-model="list" tag="tbody" @change="dragChanged()">
          <tr v-for="item in list" :key="item.id">
            <td scope="row">
              <div class="ordering-columns">
                <div class="icon icon-ordering"></div>
                <div class="ordering-number">
                  {{ item.nrOrder + 1 }}
                </div>
              </div>
            </td>
            <td>{{ item.title }}</td>
            <td>
              <div
                v-if="item.stepFiles.length > 0"
                @click="openFileModal(item.stepFiles)"
                class="icon icon-folder"
              ></div>
            </td>
            <td>
              <div class="column-actions-content">
                <div
                  class="icon icon-edit"
                  :class="item.canUpdateOrDelete ? '' : 'disable-icon'"
                  @click="eventEdit(item)"
                ></div>
                <div
                  class="icon icon-delete"
                  :class="item.canUpdateOrDelete ? '' : 'disable-icon'"
                  @click="eventDeleteConfirm(item)"
                ></div>
              </div>
            </td>
          </tr>
        </draggable>
      </table>
    </b-card>
    <b-row class="my-5">
      <b-button
        variant="outline-primary"
        :disabled="this.loading"
        @click="this.onCancel"
        class="ml-4"
      >
        Cancelar
      </b-button>
      <b-button
        form="alter-form"
        type="button"
        class="ml-4"
        variant="primary"
        id="submitButtonText"
        :disabled="this.loading"
        @click="this.onSubmitHandler"
        v-if="this.training.trainingStatus != 'REGISTER_FINISHED_AND_VISIBLE'"
      >
        Concluir cadastro e exibir para os cooperados
      </b-button>
      <success-modal
        ref="success-modal"
        :description="
          form.id
            ? 'Trilha editada com sucesso.'
            : 'Trilha cadastrada com sucesso.'
        "
        buttonText="Ok, entendi!"
      >
      </success-modal>
    </b-row>
    <FilesModal :fileList="this.fileList" />
    <attention-modal
      modalId="update-trilha-attention-modal"
      title="Atenção!"
      cancelButtonText="Não tenho certeza"
      ref="update-trilha-attention-modal"
      :onAction="onEventConfirmDelete"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          Tem certeza que deseja excluir este passo da trilha?
        </p>
      </template>
    </attention-modal>
  </div>
</template>
<script>
import UploadFileBucket from "../upload/UploadFileBucket.vue";
import UploadFileBucketMultiple from "../upload/UploadFileBucketMultiple.vue";
import FilesModal from "./FilesModal.vue";
import draggable from "vuedraggable";
import {
  validateField,
  requiredFieldsFilled,
} from "../../../../utils/validate/validate.js";
import { createToast } from "../../../../components/toast/toast";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
import RoutesPaths from "../../../../router/router-structure/routes-paths";

import {
  getStepsByTrainingId,
  reorderSteps,
  deleteStep,
  saveStep,
  updateStep,
  updateTrainingActivation,
  getTrainingById,
} from "../../../../services/training/trainingManagementService";

import {
  getFileRef,
  deleteFile,
} from "../../../../services/training/trainingUploadFileService";

const DEFAULT_FORM = {
  title: "",
  link: "",
  type: null,
};
export default {
  name: "trail-information-training",
  props: ["onChangeTab", "index"],
  components: {
    AttentionModal,
    UploadFileBucket,
    UploadFileBucketMultiple,
    FilesModal,
    draggable,
    SuccessModal,
  },
  async mounted() {
    if (!this.$route.params.id) {
      createToast(
        "Atenção",
        "Informações do treinamento não foi cadastrado.",
        "warning"
      );
      this.$props.onChangeTab(this.$props.index - 1);
      return;
    }
    this.training = await getTrainingById(this.$route.params.id);
    this.initTypeOptions();
    this.updateTableSteps();
  },
  data() {
    return {
      itemSelected: null,
      row: null,
      progress: 0,
      max: 100,
      validations: {},
      formSubmited: false,
      typeOptions: [],
      loading: false,
      training: {},
      trainingStatus: null,
      form: { ...DEFAULT_FORM },
      validateField,
      msgErroInvalid: "É necessário o anexo do documento",
      fileList: [],
      fileListToBucketDeletion: [],
      links: [],
      list: [],
      requiredFieldsFilled,
      loadingUploadFilePrincipal: false,
      loadingUploadFileMultiple: false,
    };
  },
  methods: {
    initTypeOptions() {
      this.typeOptions = [
        {
          text: "VIDEO",
          value: { text: "VIDEO", type: "VIDEO", stepName: "ASSISTA AO VÍDEO" },
        },
        {
          text: "PDF",
          value: { text: "PDF", type: "PDF", stepName: "LEIA O MATERIAL" },
        },
        {
          text: "LINK",
          value: {
            text: "LINK",
            type: "FORM",
            stepName: "TESTE SEUS CONHECIMENTOS",
          },
        },
      ];
    },
    onChangeStepType(type) {
      const typeEqual = this.typeOptions.some(
        (t) => t.value.stepName == this.form.title
      );
      if (typeEqual || this.form.title == "") {
        this.form.title = type.stepName;
      }
    },
    async updateTableSteps() {
      if (this.$route.params.id) {
        try {
          this.loading = true;
          const steps = await getStepsByTrainingId(this.$route.params.id);
          this.list = steps;
        } catch (error) {
          console.error("Error: trying to execute mounted", error);
          createToast("Atenção", "Ocorreu um erro ao consultar trilha.");
        }
        this.loading = false;
      }
    },
    async onSubmitHandler() {
      updateTrainingActivation(
        this.$route.params.id,
        "REGISTER_FINISHED_AND_VISIBLE"
      )
        .then(() => {
          this.onCancel();
        })
        .catch((error) => {
          if (error.response && error.response.status == 400) {
            createToast(
              "Informação",
              "Não é possível alterar o status. Motivo: " +
                error.response.data.message,
              "warning"
            );
          } else {
            console.error("error on AlterForm onSubmit: ", error);
            createToast("Atenção", "Ocorreu um erro ao atualizar o status.");
          }
        });
    },
    resetForm() {
      this.form = { ...DEFAULT_FORM };
      this.links = [];
      this.formSubmited = false;
    },
    async onSaveHandler() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.loading = true;
        try {
          const newOrder = Math.max(...this.list.map((item) => item.nrOrder));
          let stepFiles = this.links
            .filter((x) => !!x)
            .map(({ link, name, principal }, index) => {
              return { link, fileName: name, nrOrder: index, principal };
            });
          const objRequest = {
            ...this.form,
            nrOrder: Number.isInteger(newOrder) ? newOrder + 1 : 0,
            idTraining: this.$route.params.id,
            type: this.form.type.type,
            stepFiles,
          };
          if (this.form.id) {
            await updateStep(objRequest);
          } else {
            await saveStep(objRequest);
          }
          this.form = { ...DEFAULT_FORM };
          this.links = [];
          this.updateTableSteps();
          this.deleteBucketItems();
          const modal = this.$refs["success-modal"].$children[0];
          modal.show();
          this.resetForm();
        } catch (error) {
          if (error.response && error.response.status == 400) {
            const msg = `Não foi possível ${
              this.form.id ? "alterar" : "salvar"
            } o step. Motivo: ${error.response.data.message}`;
            createToast("Informação", msg, "warning");
          } else {
            console.error("onSubmit", error);
            createToast("Atenção", "Ocorreu um erro ao salvar trilha.");
          }
        }
        this.loading = false;
      }
    },
    eventToFileRemoved({ link }) {
      this.links = this.links.filter(Boolean).filter((l) => l.link != link);
    },
    eventToRemoveFileMultiple(files) {
      var principal = this.links.filter(Boolean).filter((l) => l.principal)[0];
      this.links = [];
      this.links.push(principal);
      files.forEach((item) => {
        this.links.push(item);
      });
    },
    eventToUpdateFile(files) {
      this.loadingUploadFileMultiple = false;
      let countIncrement = 1;
      const filesToSave = files.map(({ link, name, fileRef }) => {
        countIncrement += 1;
        return {
          nrOrder: countIncrement,
          link,
          name: name,
          principal: false,
          showProgress: false,
          showDeleteButton: true,
          fileRef,
        };
      });
      this.links = [
        ...this.links.filter(Boolean).filter((l) => l.principal),
        ...filesToSave,
      ];
    },
    eventUploadClickPrincipal() {
      this.loadingUploadFilePrincipal = true;
    },
    eventUploadClickMultiple() {
      this.loadingUploadFileMultiple = true;
    },
    eventErrorUploadingFileMultiple() {
      this.loadingUploadFileMultiple = false;
    },
    eventErrorUploadingFilePrincipal() {
      this.loadingUploadFilePrincipal = false;
    },
    eventToUpdateFilePrincipal(link, name) {
      this.loadingUploadFilePrincipal = false;
      this.links = this.links.filter(Boolean).filter((l) => !l.principal);
      this.links.push({
        nrOrder: this.links.length + 1,
        link,
        name,
        principal: true,
        showDeleteButton: true,
      });
    },
    markToDeletion(fileName) {
      if (this.fileListToBucketDeletion.indexOf(fileName) == -1)
        this.fileListToBucketDeletion.push(fileName);
    },
    openFileModal(fileList) {
      this.fileList = fileList;
      this.$bvModal.show("trail-files-modal");
    },
    resetStepSaving() {
      this.fileListToBucketDeletion = [];
    },
    deleteBucketItems() {
      this.fileListToBucketDeletion.forEach((item) => {
        var fileRef = getFileRef(`training/${this.training.name}/${item}`);
        deleteFile(fileRef);
      });
    },
    onCancel() {
      this.$router.push(RoutesPaths.trainingManagement.rootName());
    },
    async eventEdit(item) {
      if (!item.canUpdateOrDelete) return;
      this.links = item.stepFiles.map((link, index) => {
        return {
          ...link,
          name: link.fileName ? link.fileName : "file-" + (index + 1),
          showDeleteButton: true,
          showProgress: false,
          progress: 100,
        };
      });
      
      const value = this.typeOptions != undefined && this.typeOptions != null 
                      ? this.typeOptions.find((to) => this.checkType(to, item)).value
                      : null;

      this.form = {
        ...item,
        type: value,
      };
    },
    checkType(to ,item) {
      if (to != undefined && to != null && to.value != undefined && to.value != null) {
        return to.value.type == item.type;
      }

      return false;
    },
    async onEventConfirmDelete(confirmed) {
      if (!confirmed) return;
      await this.eventDelete(this.itemSelected);
    },
    async eventDeleteConfirm(item) {
      this.itemSelected = item;
      const modal = this.$refs["update-trilha-attention-modal"].$children[0];
      modal.show();
    },
    async eventDelete({ id, canUpdateOrDelete }) {
      if (!canUpdateOrDelete) return;
      try {
        this.loading = true;
        await deleteStep(id);
      } catch (error) {
        if (error.response && error.response.status == 400) {
          const msg = `Não foi possível excluir o step. Motivo: ${error.response.data.message}`;
          createToast("Informação", msg, "warning");
        } else {
          console.error(
            "Error on onClickHandler: trying to execute eventDelete",
            error
          );
          createToast("Atenção", "Ocorreu um erro ao excluir step.");
        }
      }
      this.loading = false;
      this.updateTableSteps();
    },
    async dragChanged() {
      try {
        this.loading = true;
        const stepsToReorder = this.list.map((item, index) => {
          return { id: item.id, order: index };
        });
        await reorderSteps(stepsToReorder);
      } catch (error) {
        console.error(
          "Error on onClickHandler: trying to execute dragChanged",
          error
        );
        createToast("Atenção", "Ocorreu um erro ao reordenar trilha.");
      }
      this.loading = false;
      this.updateTableSteps();
    },
  },
};
</script>

<style lang="scss" scoped>
.b-form-tags-button {
  display: none;
}
.column-actions-content {
  margin: auto;
  width: 55px;
}
.ordering-number {
  float: left;
  margin-left: 50px;
}
.column-trail-header {
  width: 50%;
}
.column-actions-header {
  width: 12%;
}
.ordering-head {
  width: 140px;
}
.icon {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;

  &-ordering {
    background-image: url("../../../../assets/icons/arrow-collapse-vertical.svg");
    float: left;
  }

  &-folder {
    background-image: url("../../../../assets/icons/folder-outline.svg");
    margin: auto;
  }

  &-edit {
    background-image: url("../../../../assets/icons/pencil-outline.svg");
    float: left;

    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../../assets/icons/pencil-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete {
    background-image: url("../../../../assets/icons/trash-can-outline.svg");
    float: left;
    margin-left: 6px;

    &:hover {
      background: #fbe9ed 0% 0% no-repeat padding-box;
      background-image: url("../../../../assets/icons/trash-can-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.button-include {
  display: grid;
  justify-items: end;
}
.disable-icon {
  opacity: 49.8%;
  cursor: not-allowed !important;
}
</style>
